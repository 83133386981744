// Import React
import React from 'react';

// Import CSS
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import './OurTeam.css'

// Import Data
import { Employees } from '../../data/employees';

// Import Components
import SectionTitle from '../../components/SectionTitle/SectionTitle';

// Import Swiper Components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swipe Modules
import { Navigation, FreeMode } from 'swiper/modules';

function OurTeam() {
    return (
        <section id="our-team">
            <div className="content padding responsive">
                <SectionTitle
                    arrows="none"
                    title="Nossa Equipe"
                    subtitle="A estrutura do nosso sucesso"
                    reverse={true}
                />

                <Swiper
                    loop={true}
                    slidesPerView={"auto"}
                    spaceBetween={20}
                    freeMode={true}
                    modules={[Navigation, FreeMode]}
                    navigation={true}
                    className="mySwiper"
                >
                    {Employees.map((lawyer, index) => (
                        <SwiperSlide key={index}>
                            <img src={lawyer.image} alt={lawyer.name} />

                            <div className="info">
                                <h1>{lawyer.name}</h1>
                                <h4>{lawyer.jobTitle}</h4>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>

            </div>
        </section>
    );
}

export default OurTeam;